import React, { Children, useCallback, useMemo, type PropsWithChildren } from 'react';
import { isEqual } from 'lodash';
import memoizeOne from 'memoize-one';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import { getCurrentUser, getIsPostPageBundle } from '@wix/communities-blog-client-common';
import postPageStyleParams from '@app/components/Post/stylesParams';
import { OOI_EXPERIMENTS } from '@app/experiments';
import { PermissionContext } from '../../context/permission-context';
import { getIsCommentsEnabled } from '../../selectors/app-settings-selectors';
import { getPermissionFlags } from '../../selectors/permission-selectors';
import createPermissionChecker from '../../services/create-permission-checker';
import { getIsMemberAreaInstalled } from '../../store/communities-context/communities-context-selectors';
import { useSelector } from '../runtime-context';

const createPermissions = memoizeOne(
  createPermissionChecker,
  (a, b) => isEqual(a[1], b[1]) && isEqual(a[2], b[2]),
);

const PermissionsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const currentUser = useSelector(getCurrentUser);
  const statePosts = useSelector((state) => state.posts);
  const permissionFlags = usePermissionFlags();

  const permissions = useMemo(
    () => createPermissions({ posts: statePosts }, currentUser, permissionFlags),
    [statePosts, currentUser, permissionFlags],
  );

  return (
    <PermissionContext.Provider value={permissions}>
      {Children.only(children)}
    </PermissionContext.Provider>
  );
};

type PermissionFlags = {
  isMemberAreaInstalled: boolean;
  isCommentsEnabled: boolean;
  isSocialSharingEnabled: boolean;
};

function usePermissionFlags(): PermissionFlags {
  const { experiments } = useExperiments();
  const isMemberAreaInstalled = useSelector(getIsMemberAreaInstalled);
  const legacyPermissionFlags = useSelector(getPermissionFlags);
  const isCommentsEnabled = useSelector(getIsCommentsEnabled);
  const isPostPageBundle = useSelector(getIsPostPageBundle);
  const isSocialSharingAllowedOnPostPage = usePostPageSocialSharingEnabledCallback();
  const permissionFlags: PermissionFlags =
    experiments.enabled(OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL) && isPostPageBundle
      ? {
          isMemberAreaInstalled,
          isCommentsEnabled,
          isSocialSharingEnabled: isSocialSharingAllowedOnPostPage(),
        }
      : legacyPermissionFlags;

  return permissionFlags;
}

function usePostPageSocialSharingEnabledCallback() {
  const styles = useStyles();

  const checker = useCallback(() => {
    const hasShareEnabled = styles
      .getStylesForAllBreakpoints()
      .some((style) => style.booleans[postPageStyleParams.showShare.key!]);

    const hasShareIconEnabled = styles
      .getStylesForAllBreakpoints()
      .some(
        (style) =>
          style.booleans[postPageStyleParams.showShareIconPrint.key!] ||
          style.booleans[postPageStyleParams.showShareIconFacebook.key!] ||
          style.booleans[postPageStyleParams.showShareIconLink.key!] ||
          style.booleans[postPageStyleParams.showShareIconLinkedIn.key!] ||
          style.booleans[postPageStyleParams.showShareIconX.key!],
      );

    return hasShareEnabled && hasShareIconEnabled;
  }, [styles]);

  return checker;
}

export default PermissionsProvider;
